@keyframes openModal {
  0% {
    transform: translate3d(var(--open-x), var(--open-y), 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.container {
  animation: openModal 0.35s ease;
}

.defaultContainer {
  --open-x: 0;
  --open-y: 50%;
}

.rightSidebarContainer {
  --open-x: 50%;
  --open-y: 0;
}

.leftSidebarContainer {
  --open-x: -50%;
  --open-y: 0;
}

.bottomContainer {
  --open-x: 0%;
  --open-y: 100%;
}

.plainModal,
.sidebar {

  &,
  &:focus {
    outline: none;
  }
}


:global(.mobile) {

  .underHeader {
    margin-top: var(--header-height);
  }

  .plainModal,
  .sidebar {
    width: 100%;
    min-height: 100%;
  }

  .bottomContainer {
    top: unset;
  }

  .fullscreen {
    width: 100%;
    min-height: 100%;
  }
}

:global(.desktop) {

  .overlay {
    display: grid;
  }

  .defaultContainer {
    margin: auto;
    padding: 16rem;
  }

  .topFullscreenContainer {
    align-self: start;
  }

  .rightSidebarContainer {
    justify-self: end;
  }

  .leftSidebarContainer {
    justify-self: start;
  }

  .bottomContainer {
    align-self: end;
  }

  .plainModal {
    width: 640rem;
    max-width: 100%;
    min-height: 382rem;
  }

  .sidebar {
    width: 464rem;
    min-height: 100%;
  }

  .fullscreen {
    min-width: 100%;
  }
}
