:global(.mobile) {

  .svg rect {
    transform-origin: center;
    transition: all 0.2s ease;
  }

  .open {
    :global {
      .mid-line {
        opacity: 0;
      }

      .top-line {
        transform: rotate(45deg) translate(0, 20%);
      }

      .bot-line {
        transform: rotate(-45deg) translate(0, -20%);
      }
    }
  }
}
