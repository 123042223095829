.overlay {
  @apply z-global-loader;
}

.container {
  background: rgba(255, 255, 255, 0.9) !important;
}

.loader {
  width: 134rem;
  transform: translate3d(0, 0, 0);
}

.animated {
  animation-duration: 3.18s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.bStartMask {
  animation-name: bStartMask;
}

.bStart {
  animation-name: bStart;
}

.bEndDown {
  animation-name: bEndDown;
}

.bEndUp {
  animation-name: bEndUp;
}

.sDown {
  animation-name: sDown;
}

.sUp {
  animation-name: sUp;
}

@keyframes bStartMask {
  0% {
    transform: translate(698px, 1082px) scale(1, 1) translate(500px, 82px);
  }

  91.1949% {
    transform: translate(698px, 1082px) scale(1, 1) translate(500px, 82px);
    animation-timing-function: cubic-bezier(0.75, 0, 0.67, 1);
  }

  91.8239% {
    transform: translate(698px, 1082px) scale(0, 1) translate(500px, 82px);
  }

  100% {
    transform: translate(698px, 1082px) scale(0, 1) translate(500px, 82px);
  }
}

@keyframes bStart {
  0% {
    stroke-dasharray: 0 1540px;
    stroke-dashoffset: -693px;
    stroke-opacity: 0;
  }

  18.8679% {
    animation-timing-function: cubic-bezier(0.497, 0.007, 0.504, 1);
    stroke-dasharray: 0 1540px;
    stroke-dashoffset: -693px;
    stroke-opacity: 0;
  }

  19% {
    stroke-opacity: 1;
  }

  35.8491% {
    animation-timing-function: cubic-bezier(1, 0, 0.833, 1);
    stroke-dasharray: 1540px 1540px;
    stroke-dashoffset: 0;
  }

  82.3899% {
    animation-timing-function: cubic-bezier(0.167, 0, 0.833, 1);
    stroke-dasharray: 1540px 1540px;
    stroke-dashoffset: 0;
  }

  91.8239% {
    stroke-dasharray: 278.5px 1540px;
    stroke-dashoffset: -557px;
  }

  100% {
    stroke-dasharray: 278.5px 1540px;
    stroke-dashoffset: -557px;
    stroke-opacity: 1;
  }
}

@keyframes bEndDown {

  0%,
  93.7107%,
  to {
    stroke-dashoffset: 2512px;
  }

  27.673% {
    animation-timing-function: cubic-bezier(.199, .102, .295, 1.002);
    stroke-dashoffset: 2512px;
  }

  52.2013% {
    animation-timing-function: cubic-bezier(.167, 0, .67, 1);
    stroke-dashoffset: 0;
  }

  69.1824% {
    animation-timing-function: cubic-bezier(.393, 0, .737, .368);
    stroke-dashoffset: 0;
  }

  76.1006% {
    animation-timing-function: cubic-bezier(.581, .223, .608, 1);
    stroke-dashoffset: 148px;
  }
}

@keyframes bEndUp {

  0%,
  93.7107%,
  to {
    stroke-dashoffset: 2304px;
  }

  27.673% {
    animation-timing-function: cubic-bezier(.143, .001, .251, .997);
    stroke-dashoffset: 2304px;
  }

  52.2013% {
    animation-timing-function: cubic-bezier(.167, 0, .67, 1);
    stroke-dashoffset: 0;
  }

  69.1824% {
    animation-timing-function: cubic-bezier(.75, 0, .67, 1);
    stroke-dashoffset: 0;
  }
}

@keyframes sDown {

  0% {
    animation-timing-function: cubic-bezier(.249, .25, .249, 1);
    stroke-dashoffset: 2221px;
  }

  37.7358% {
    animation-timing-function: cubic-bezier(.167, 0, .67, 1);
    stroke-dashoffset: 0;
  }

  78.6164% {
    animation-timing-function: cubic-bezier(.75, 0, .67, 1);
    stroke-dashoffset: 0;
  }

  93.7107%,
  to {
    stroke-dashoffset: 2221px;
  }
}

@keyframes sUp {
  0% {
    animation-timing-function: cubic-bezier(.249, .25, .249, 1);
    stroke-dashoffset: 2150px;
  }

  37.7358% {
    animation-timing-function: cubic-bezier(.167, 0, .67, 1);
    stroke-dashoffset: 0;
  }

  78.6164% {
    animation-timing-function: cubic-bezier(.75, 0, .67, 1);
    stroke-dashoffset: 0;
  }

  93.7107%,
  to {
    stroke-dashoffset: 2150px;
  }
}
