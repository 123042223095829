.grid {
  grid-template-rows: auto 1fr; /* required for Safari */
}

:global(.desktop) {
  .carousel {
    :global {
      .glider {
        margin: -8rem;
        padding: 8rem 0;
      }

      .glider-next {
        right: -36rem;
      }

      .glider-prev {
        left: -36rem;
      }
    }
  }
}
