.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  text-decoration: none !important;
  vertical-align: top;
  background: none;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  @apply select-none transition-all duration-200 ease-out;

  &:not(.disabled) {

    &:hover,
    &:focus {
      @apply duration-0;
    }
  }
}

/* Sizes */

.size-56 {
  height: 56rem;
  font-size: 16rem;
}

.size-48 {
  height: 48rem;
  font-size: 16rem;
}

.size-38 {
  height: 38rem;
  font-size: 14rem;
}

.size-26 {
  height: 26rem;
  font-size: 12rem;
}

/* Modifies */

.loading,
.disabled {
  cursor: default;
}


/* Styles */

.styled {
  --border-color: transparent;
  --text-transform: uppercase;
  --hover-color: var(--color);
  --hover-bg-color: var(--bg-color);
  --hover-border-color: var(--border-color);
  color: var(--color);
  text-transform: var(--text-transform);
  background-color: var(--bg-color);
  border-color: var(--border-color);

  &.disabled {
    @apply bg-gray-50;
  }

  @media (hover: hover) {

    &:not(.disabled) {

      &:hover,
      &:active {
        color: var(--hover-color);
        border-color: var(--hover-border-color);
        background-color: var(--hover-bg-color);
      }
    }
  }
}

.primary {
  --keyboard-focus-color: theme('colors.black.DEFAULT');
  --bg-color: theme('colors.black.DEFAULT');
  --color: theme('colors.white');
  --hover-bg-color: theme('colors.gray.90');
}

.secondary {
  --bg-color: transparent;
  --color: theme('colors.black.DEFAULT');
  --hover-color: theme('colors.gray.80');
  --border-color: currentColor;
}

.tertiary {
  --color: theme('colors.black.DEFAULT');
  --bg-color: transparent;
  --hover-color: theme('colors.gray.80');
  --text-transform: none;
}

.gold-30 {
  --keyboard-focus-color: theme('colors.gold.50');
  --color: theme('colors.black.DEFAULT');
  --bg-color: theme('colors.gold.30');
  --hover-bg-color: theme('colors.gold.50');

  &.disabled {
    @apply text-white;
    @apply bg-pink;
  }
}

.gold-30-secondary {
  --keyboard-focus-color: theme('colors.gold.50');
  --color: theme('colors.gold.30');
  --bg-color: transparent;
  --border-color: currentColor;
  --hover-color: theme('colors.gold.50');

  &.disabled {
    @apply opacity-60;
    @apply bg-transparent;
    @apply cursor-not-allowed;
  }
}

.gold-50-secondary {
  --keyboard-focus-color: theme('colors.gold.70');
  --color: theme('colors.gold.50');
  --bg-color: transparent;
  --border-color: theme('colors.gold.30');
  --hover-color: theme('colors.gold.70');
  --hover-border-color: theme('colors.gold.70');

  font-weight: 700;

  &.disabled {
    @apply opacity-60;
    @apply bg-transparent;
    @apply cursor-not-allowed;
  }
}

.red {
  --keyboard-focus-color: theme('colors.red');
  --color: theme('colors.white');
  --bg-color: theme('colors.red');
  --hover-bg-color: #c30606;
}

.apple {
  --bg-color: theme('colors.white');
  --color: theme('colors.black.DEFAULT');
  --hover-color: theme('colors.gray.80');
  --border-color: theme('colors.gray.50');
}

.apple-black-border {
  --bg-color: theme('colors.white');
  --color: theme('colors.black.DEFAULT');
  --hover-color: theme('colors.gray.80');
  --border-color: theme('colors.black.DEFAULT');
}
